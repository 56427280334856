import Loader from 'components/layout/Loader';
import PageTitle from 'components/layout/PageTitle';
import ScrollToTop from 'components/layout/ScrollToTop';
import Layout from 'layouts';
import NotFoundPage from 'pages/system/404';
import React from 'react';
import Loadable from 'react-loadable';
import { useSelector } from 'react-redux';
import { BrowserRouter, Redirect, Route } from 'react-router-dom';
import Switch from 'react-router-transition-switch';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import labConfig from 'utils/labConfig';

const loadable = (loader) =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  });

const routes = [
  {
    path: '/system/login',
    Component: loadable(() => import('pages/system/login')),
    exact: true,
    title: 'Login',
  },
  {
    path: '/system/forgot-password',
    Component: loadable(() => import('pages/system/forgot-password')),
    exact: false,
    title: 'Forgot Password',
  },
  {
    path: '/system/restore-password',
    Component: loadable(() => import('pages/system/restore-password')),
    exact: false,
    title: 'Restore Password',
  },
  {
    path: '/system/404',
    Component: loadable(() => import('pages/system/404')),
    exact: true,
    title: 'Not Found',
  },
  {
    path: '/profile',
    Component: loadable(() => import('pages/Profile')),
    exact: true,
  },
  {
    path: '/barcode-lookup',
    Component: loadable(() => import('pages/Search')),
  },
  {
    path: '/pools',
    Component: loadable(() => import('pages/Pools')),
  },
  {
    path: '/session/:id',
    Component: loadable(() => import('pages/Scan')),
    title: null,
  },
  {
    path: '/pool-scans/:sessionId/:scanId',
    Component: loadable(() => import('pages/PoolScan')),
    title: 'Pool Scan',
  },
  {
    path: '/pool-scans',
    Component: loadable(() => import('pages/ScanSessions')),
  },
  {
    path: '/rack-scans/:id',
    Component: loadable(() => import('pages/RackScan')),
    title: `${labConfig[process.env.REACT_APP_LAB_ID].naming.rack} Scan`,
  },
  {
    path: '/rack-scans',
    Component: loadable(() => import('pages/RackScans')),
    title: `${labConfig[process.env.REACT_APP_LAB_ID].naming.rack} Scans`,
  },
  {
    path: '/intake-receipt-log',
    Component: loadable(() => import('pages/IntakeReceiptLog')),
  },
  {
    path: '/analysis-runs/:id/:type?',
    Component: loadable(() => import('pages/AnalysisRun')),
    title: 'Run',
  },
  {
    path: '/analysis-runs',
    Component: loadable(() => import('pages/AnalysisRuns')),
  },
  {
    path: '/reflex-list/:sampleId',
    Component: loadable(() => import('pages/ReflexComparison')),
  },
  {
    path: '/reflex-list',
    Component: loadable(() => import('pages/ReflexList')),
  },
  {
    path: '/companies',
    Component: loadable(() => import('pages/Companies')),
    exact: true,
  },
  {
    path: '/companies/:id',
    Component: loadable(() => import('pages/Companies/Company')),
    title: 'Company',
  },
  // {
  //   path: '/inventory',
  //   Component: loadable(() => import('pages/Inventory')),
  // },
  {
    path: '/generate-run',
    Component: loadable(() => import('pages/RunTemplate')),
  },
  {
    path: '/runs/:id',
    Component: loadable(() => import('pages/Runs/Run')),
  },
  {
    path: '/runs',
    Component: loadable(() => import('pages/Runs')),
  },
  {
    path: '/intake-dashboard',
    Component: loadable(() => import('pages/IntakeDashboard')),
  },
  {
    path: '/sample-count',
    Component: loadable(() => import('pages/SampleCount')),
  },
  {
    path: '/settings/permission',
    Component: loadable(() => import('pages/Settings/Permissions')),
  },
];

const Router = () => {
  const settings = useSelector((state) => state.settings);
  const { routerAnimation } = settings;

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Layout>
        <Switch
          render={(props) => {
            const {
              children,
              location: { pathname },
            } = props;
            return (
              <SwitchTransition>
                <CSSTransition
                  key={pathname}
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  {children}
                </CSSTransition>
              </SwitchTransition>
            );
          }}
        >
          <Route
            exact
            path="/"
            render={() => {
              return <Redirect to="/intake-receipt-log" />;
            }}
          />
          {routes.map(({ path, Component, exact = false, title }) => (
            <Route path={path} key={path} exact={exact}>
              <PageTitle title={title}>
                <Component />
              </PageTitle>
            </Route>
          ))}
          <Route component={NotFoundPage} />
        </Switch>
      </Layout>
    </BrowserRouter>
  );
};

export default Router;
